import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScroolTop";
import Pagenotfound404 from "./pages/Pagenotfound";


const Homepage = lazy(() => import("./pages/Home"));
const AboutUs = lazy(() => import("./pages/AboutUs"));
const Portofolio = lazy(() => import("./pages/Portofolio"));
const ContactUs = lazy(() => import("./pages/ContactUs"));

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Suspense fallback={<div className="loading2"><div className="loading"></div></div>}>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/about_us" element={<AboutUs />} />
          <Route path="/portofolio" element={<Portofolio />} />
          <Route path="/contact_us" element={<ContactUs />} />
          <Route path="*" element={<Pagenotfound404 />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
